<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="font-18 mx-auto">Detail Pelamar</h1>
      <router-link
        :to="{ name: 'Dashboard' }"
        @click="menuOpen('menu-main')"
        data-menu="menu-main"
        class="page-title-icon rounded-s ms-3"
      ></router-link>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->
    <div class="page-content mt-4">
      <div v-if="user" class="card card-style pb-2">
        <div class="content mt-3 mb-2">
          <div class="text-center mt-2 mb-4">
            <img
              :src="photoUrl"
              class="rounded-m me-3"
              style="height: 84px; width: 84px; object-fit: cover"
            />
            <div class="mt-2">
              <a v-if="user.first_name" class="color-theme">
                {{ user.first_name }} {{ user.last_name }}</a
              >
            </div>
            <div class="">
              <a
                v-if="user.user"
                :href="`mailto:${user.user.email}`"
                class="color-theme"
                >{{ user.user.email }}
                <i class="fas color-highlight fa-envelope font-15"></i>
              </a>
            </div>

            <div class="">
              <a
                v-if="user.user"
                :href="`https://api.whatsapp.com/send?phone=${user.user.mobile_no}`"
                class="color-theme"
              >
                {{ user.user.mobile_no }}
                <i
                  class="
                    fab
                    fa-whatsapp
                    font-15
                    bg-whatsapp
                    color-white
                    shadow-l
                    rounded-s
                  "
                ></i
              ></a>
            </div>
          </div>

          <div v-if="user.introduction_letter">
            <strong class="">Tentang pelamar : </strong>
            <p>{{ user.introduction_letter }}</p>
          </div>

          <div v-if="user.last_degree" class="vcard-field">
            <strong class="">Lulusan : </strong>
            {{ user.last_degree }}
          </div>

          <div v-if="user.current_job_title" class="vcard-field">
            <strong class="">Pekerjaan : </strong>
            {{ user.current_job_title }}
          </div>

          <div v-if="user.previous_job.length" class="vcard-field">
            <strong class="">Pengalaman Pekerjaan :</strong>
            <span v-for="exp in user.previous_job" :key="exp.name">
              {{ exp.name }},&nbsp;</span
            >
          </div>

          <div v-if="user.current_job_start" class="vcard-field">
            <strong class="">Mulai Bekerja : </strong>
            {{ user.current_job_start }}
          </div>
        </div>
      </div>
      <!-- User CV -->
      <div v-if="cvUrl" class="card card-style pb-2">
        <div class="content mt-3 mb-2">
          <div class="d-flex">
            <div class="align-self-center me-auto">
              <h5 class="color-gray-dark">Silahkan download cv pelamar</h5>
            </div>
            <div class="align-self-center">
              <a
                :href="cvUrl"
                class="
                  btn btn-s
                  float-end
                  gradient-highlight
                  rounded-s
                  font-13 font-600
                "
              >
                <i class="fas fa-file-download select-all font-14 me-1"></i>
                Unduh CV</a
              >
            </div>
          </div>
          <div><pdf :src="cvUrl"> </pdf></div>
        </div>
      </div>
      <!-- End User CV -->
    </div>

    <!-- Main Menu-->
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import pdf from "pdfvuer";
export default {
  name: "DetailApplyJob",
  components: {
    Header,
    Footer,
    pdf,
  },
  data() {
    return {
      user: "",
      apiUrl: "/api/v1/employer/job/apply/",
      photoUrl: "",
      isLoading: true,
      cvUrl: "",
    };
  },
  mounted() {
    init_template();
    var job_id = this.$route.params.job_id;
    var user_id = this.$route.params.user_id;
    this.getUser(job_id, user_id);
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    menuOpen,
    menuClose,
    getUser(job_id, user_id) {
      this.apiUrl = this.apiUrl + job_id + "/" + user_id + "/";
      axios
        .get(this.apiUrl)
        .then((response) => {
          console.log(response.data);
          this.user = response.data[0];
          this.photoUrl = this.user.get_photo;
          if (response.data[0].resume_upload) {
            this.cvUrl = axios.defaults.baseURL + response.data[0].get_resume;
            console.log(this.cvUrl);
          }
        })
        .catch((error) => {});
    },
  },
};
</script>
